<template>
	<div class="main" v-loading="loading">
		<div class="mian-center">
			<div class="top">
				<div class="top-row1 fl">
					<div class="fl lable1">{{totalNum}}</div>
					<div class="fl lable2">RTS项目总数(个）</div>
				</div>
				<!-- <div class="top-row2 fl" id="chartPie" :option="option"></div> -->
				<!-- <dv-capsule-chart :config="config" class="top-row3 fl" /> -->
			</div>
			<div>
				<div class="chaxu">
					<el-form ref="form" :model="form" label-width="100px" class="flex">
						<el-form-item label="项目名称：">
							<el-input v-model="form.name" class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目所在地：">
							<el-input v-model="form.region" class="bbb"></el-input>
						</el-form-item>
						
						<el-form-item label="项目类型：">
							<el-select v-model="form.region1" placeholder="请选择项目类型" @change="search" clearable>
								<el-option label="全部" value=""></el-option>
								<el-option label="热风炉" value="rfl"></el-option>
								<el-option label="加热炉" value="jrl"></el-option>
								<el-option label="加热炉二级" value="jrlse"></el-option>
								<el-option label="燃气炉" value="rql"></el-option>
								<el-option label="烧结机" value="sjj"></el-option>
								<el-option label="石灰窑(回转窑)" value="hzy"></el-option>
								<el-option label="石灰窑(双梁式)" value="sls"></el-option>
								<el-option label="竖炉优化" value="slyh"></el-option>						
								<el-option label="CFB" value="cfb"></el-option>
								<el-option label="煤粉炉" value="mfl"></el-option>
								<el-option label="环保岛" value="hbd"></el-option>
								<el-option label="燃烧装置" value="rszz"></el-option>
								<el-option label="蒸汽管网" value="zqgw"></el-option>
								<el-option label="电除尘" value="dcc"></el-option>							
								<el-option label="化工装置" value="hgzz"></el-option>
								<el-option label="精馏塔" value="jlt"></el-option>
								<el-option label="反应釜" value="fyf"></el-option>
								<el-option label="PVC旋风干燥" value="pvcx"></el-option>
								<el-option label="PVC流化床干燥" value="pvcl"></el-option>
								<el-option label="水泥生产线" value="snscx"></el-option>
								<el-option label="水泥磨" value="snm"></el-option>
								<el-option label="硫回收" value="lhs"></el-option>
								<el-option label="合成氨" value="hca"></el-option>
								<el-option label="焙烧炉" value="bsl"></el-option>
								<el-option label="聚合釜" value="jhf"></el-option>
								<el-option label="专业培训" value="train"></el-option>
							
							</el-select>
							<el-button type="primary" class="subtn" @click="search">搜索</el-button>
							<el-button type="primary" class="subtn" @click="addsut">新增项目</el-button>
							<el-button type="primary" class="subtn" @click="addsut1">用户管理</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div class="main-table">
					<el-table class="table-h" :data="newArry"
						style="width: 100%；font-family: PingFang-SC-Regular;font-size:1vw" height="45vh"
						:row-class-name="tableRowClassName" fixed
						:default-sort="{prop: 'project_start_day', order: 'descending'}">
						<el-table-column label="序号" align="center" width="80">
							<template slot-scope="scope">{{ scope.$index + 1 }}</template>
						</el-table-column>
						<el-table-column prop="project_name" label="项目名称" align="center" width="250">
						</el-table-column>
						<el-table-column prop="project_start_day" align="center" label="接入RTS日期" sortable width="160">
						</el-table-column>
						<el-table-column prop="salesperson" align="center" label="商务经理" >
						</el-table-column>
						<el-table-column prop="engineer" align="center" label="工程经理" >
						</el-table-column>
						<el-table-column prop="phone" align="center" label="联系电话" width="160">
						</el-table-column>
						<el-table-column prop="place" align="center" label="项目所在地" width="280">
						</el-table-column>
						<el-table-column prop="product_type_name" align="center" label="项目类型" >
						</el-table-column>
						<el-table-column align="center" label="操作" width="150">
							<template slot-scope="scope">
								<el-button type="info" size="small" @click.stop="editPro(scope.row)">修改</el-button>
								<el-button type="danger" size="small" @click.stop="deletPro(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-pagination class="jiange" @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog :visible.sync="centerDialogVisible" width="50vw" custom-class="addPro" :show-close="false" center>
			<div class="addBox " style="height:300px">
				<div class="evaluate-header">
					{{tit}}
					<div class="evaluate-header-icon" @click="centerDialogVisible = false">
						<!-- <span class="el-icon-close"></span> -->
						<img src="~@/assets/images/close.png" style="width: 100%; height: auto" />
					</div>
				</div>
				<!-- 表单 -->
				<div class="flex">
					<el-form :label-position="labelPosition" label-width="130px" :model="formLabelAlign" class="addform"
						:rules="rules" ref="ruleForm">
						<el-form-item label="设备号：" prop="device_name">
							<el-input v-model="formLabelAlign.device_name" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目名称：" prop="project_name">
							<el-input v-model="formLabelAlign.project_name" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目全称：" prop="ht_name">
							<el-input v-model="formLabelAlign.ht_name" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="合同编号：" prop="ht_no">
							<el-input v-model="formLabelAlign.ht_no" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目开始时间：" prop="project_start_day">
							<el-date-picker v-model="formLabelAlign.project_start_day" type="date" placeholder="选择日期"
								format="yyyy-MM-d" value-format="yyyy-MM-dd">
							</el-date-picker>
							<!-- <el-input v-model="" class="bbb"></el-input> -->
						</el-form-item>
						<el-form-item label="商务经理：" prop="salesperson">
							<el-input v-model="formLabelAlign.salesperson" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="工程经理：" prop="engineer">
							<el-input v-model="formLabelAlign.engineer" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="联系电话：" prop="phone">
							<el-input v-model="formLabelAlign.phone" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="项目所在地：" prop="place">
							<el-input v-model="formLabelAlign.place" clearable class="bbb" placeholder="点击选择地点"
								suffix-icon="el-icon-location" @click.native="openBm" style="cursor: pointer;" disabled>
							</el-input>
						</el-form-item>
						<el-form-item label="行业：" prop="industry">
							<el-select v-model="formLabelAlign.industry" placeholder="请选择所属行业">
								<el-option label="冶金" value="冶金"></el-option>
								<el-option label="热电" value="热电"></el-option>
								<el-option label="煤化工" value="煤化工"></el-option>
								<el-option label="氯碱化工" value="氯碱化工"></el-option>
								<el-option label="建材" value="建材"></el-option>						
							</el-select>
						</el-form-item>
						<el-form-item label="样板工程：" prop="industry">
							<el-select v-model="formLabelAlign.good" placeholder="请选择">
								<el-option label="是" :value="1"></el-option>
								<el-option label="否" :value="0"></el-option>				
							</el-select>
						</el-form-item>
						<el-form-item label="项目类型名称：" prop="product_type_name">
							<el-select v-model="formLabelAlign.product_type_name" placeholder="请选择项目类型">
								<el-option label="热风炉" value="热风炉"></el-option>
								<el-option label="加热炉" value="加热炉"></el-option>
								<el-option label="加热炉二级" value="加热炉二级"></el-option>
								<el-option label="燃气炉" value="燃气炉"></el-option>
								<el-option label="烧结机" value="烧结机"></el-option>
								<el-option label="石灰窑(回转窑)" value="回转窑"></el-option>
								<el-option label="石灰窑(双梁式)" value="双梁式"></el-option>
								<el-option label="竖炉优化" value="竖炉优化"></el-option>
								
								<el-option label="CFB" value="CFB"></el-option>
								<el-option label="煤粉炉" value="煤粉炉"></el-option>
								<el-option label="环保岛" value="环保岛"></el-option>
								<el-option label="燃烧装置" value="燃烧装置"></el-option>
								<el-option label="蒸汽管网" value="蒸汽管网"></el-option>
								<el-option label="电除尘" value="电除尘"></el-option>
								
								<el-option label="化工装置" value="化工装置"></el-option>
								<el-option label="精馏塔" value="精馏塔"></el-option>
								<el-option label="反应釜" value="反应釜"></el-option>
								<el-option label="PVC旋风干燥" value="PVC旋风干燥"></el-option>
								<el-option label="PVC流化床干燥" value="PVC流化床干燥"></el-option>
								<el-option label="水泥生产线" value="水泥生产线"></el-option>
								<el-option label="水泥磨" value="水泥磨"></el-option>
								<el-option label="硫回收" value="硫回收"></el-option>
								<el-option label="合成氨" value="合成氨"></el-option>
								<el-option label="焙烧炉" value="焙烧炉"></el-option>
								<el-option label="聚合釜" value="聚合釜"></el-option>
								<el-option label="专业培训" value="专业培训"></el-option>
								<el-option label="烧碱蒸发" value="烧碱蒸发"></el-option>
								<el-option label="烧碱蒸发" value="烧碱蒸发"></el-option>
								
								<!-- <el-option label="电除尘" value="电除尘"></el-option> -->
							</el-select>
						</el-form-item>
						<el-form-item label="项目类型代码：" prop="product_type">
							<el-select v-model="formLabelAlign.product_type" placeholder="请选择项目类型代码">
								<el-option label="rfl" value="rfl"></el-option>
								<el-option label="jrl" value="jrl"></el-option>
								<el-option label="jrlse" value="jrlse"></el-option>
								<el-option label="rql" value="rql"></el-option>
								<el-option label="cfb" value="cfb"></el-option>
								<el-option label="sjj" value="sjj"></el-option>
								<el-option label="hzy" value="hzy"></el-option>
								<el-option label="sls" value="sls"></el-option>
								<el-option label="slyh" value="slyh"></el-option>								
								<el-option label="mfl" value="mfl"></el-option>
								<el-option label="hbd" value="hbd"></el-option>
								<el-option label="rszz" value="rszz"></el-option>
								<el-option label="zqgw" value="zqgw"></el-option>
								<el-option label="dcc" value="dcc"></el-option>			
								<el-option label="hgzz" value="hgzz"></el-option>
								<el-option label="jlt" value="jlt"></el-option>
								<el-option label="fyf" value="fyf"></el-option>
								<el-option label="pvcx" value="pvcx"></el-option>
								<el-option label="pvcl" value="pvcl"></el-option>
								<el-option label="snscx" value="snscx"></el-option>
								<el-option label="snm" value="snm"></el-option>
								<el-option label="lhs" value="lhs"></el-option>
								<el-option label="hca" value="hca"></el-option>
								<el-option label="bsl" value="bsl"></el-option>
								<el-option label="jhf" value="jhf"></el-option>
								<el-option label="train" value="train"></el-option>
								<el-option label="sjzf" value="sjzf"></el-option>
								
								<!-- <el-option label="dcc" value="dcc"></el-option> -->
							</el-select>
						</el-form-item>
						<el-form-item label="web访问地址：" prop="url">
							<el-input v-model="formLabelAlign.url" clearable class="bbb"></el-input>
						</el-form-item>
						<!-- <el-form-item label="系统用户名:" prop="sysuser">
							<el-input v-model="formLabelAlign.sysuser" clearable class="bbb"></el-input>
						</el-form-item> -->
						
						<el-form-item label="系统ip" prop="sysip">
							<el-input v-model="formLabelAlign.sysip" clearable class="bbb"></el-input>
						</el-form-item>
						<el-form-item label="系统密码" prop="syspassword">
							<el-input v-model="formLabelAlign.syspassword" clearable class="bbb"></el-input>
						</el-form-item>
					</el-form>
					<!-- <el-button >确定</el-button> -->
				</div>

				<div class="btn" @click="onsubmit('ruleForm')">确定</div>
			</div>
		</el-dialog>
		<bdmap v-if="mapBoor" @sendStatus="isshowfase" @addInfo="emitInfo" :sendformLabelAlign="formLabelAlign"></bdmap>
	</div>
</template>
<script>
	import * as echarts from "echarts";
	require("echarts/theme/macarons"); //引入主题
	import {
		getProjectlist
	} from "@/api/otherview/index" //项目信息总览
	import {
		authProjectlist
	} from "@/api/otherview/index" //用户有权查看的列表
	import Format from "../../utils/auth.js"
	import {
		getAddlist
	} from "@/api/otherview/index" //增加项目
	import {
		deletList
	} from "@/api/otherview/index" //删除项目
	import {
		editList
	} from "@/api/otherview/index" //修改项目
	import {
		panelList
	} from "@/api/otherview/index" //面板统计
	import bdmap from "@/components/Bd_map.vue"; //地图组件
	// deletList
	export default {
		data() {
			return {
				edittype: 0,
				labelPosition: 'right',
				formLabelAlign: {
					device_name: '',
					project_name: '',
					project_start_day: '',
					salesperson: '',
					engineer: '',
					phone: '',
					place: '',
					product_type: '',
					product_type_name: '',
					address: '',
					industry: '',
					url: '',
					sysuser:'',
					syspassword:'',
					sysip:'',
					ht_name:'',
					ht_no:'',
				},
				mapBoor: false,
				totalNum: 0,
				rules: {
					// device_name: [{
					// 	validator: Format.FormValidate.Form().deviceNum, //validator： 自定义验证规则
					// 	trigger: "blur",
					// 	message: '请正确输入设备号',
					// 	required: true
					// }],
					project_name: [{
							required: true,
							message: '请输入项目名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20 个字符',
							trigger: 'blur'
						}
					],
					project_start_day: [{
						required: true,
						message: '请选择时间',
						trigger: 'blur'
					}, ],
					salesperson: [{
						validator: Format.FormValidate.Form().Name, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入姓名',
						required: true
					}],
					engineer: [{
						validator: Format.FormValidate.Form().Name, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入姓名',
						required: true
					}],
					phone: [{
						validator: Format.FormValidate.Form().Tel, //validator： 自定义验证规则
						trigger: "blur",
						message: '请正确输入电话号码',
						required: true
					}],
					ht_name: [{
						required: true,
						message: '项目全称不能为空',
						required: true
					}],
					ht_no: [{
						required: true,
						message: '合同编号不能为空',
						required: true
					}],
					place: [{
						required: true,
						message: '请选择地点',
						trigger: 'blur'
					}, ],
					product_type: [{
						required: true,
						message: '请输入项目类型代码',
						trigger: 'blur'
					}, ],
					industry: [{
						required: true,
						message: '请输入所属行业',
						trigger: 'blur'
					}, ],
					product_type_name: [{
							required: true,
							message: '请选择项目类型名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 10,
							message: '长度在 3 到 10 个字符',
							trigger: 'blur'
						}
					],
					// url: [{
					// 	required: true,
					// 	message: '请输入web访问地址',
					// 	trigger: 'blur'
					// }]
				},
				tit: '新增项目',
				centerDialogVisible: false,
				chartPie: null,
				currentPage: 1, //初始页
				PageSize: 7, //每页的数据
				totalCount: 0,
				loading: false,
				pageSizes: [7, 14, 21, 40, 50, 100],
				UserInfo: [],
				newArry: [],
				authsee: false,
				form: {
					name: '',
					region: '',
					region1: '',
				},
				config: {},
				option: '',
				echartsdata: [],
				tableData: [],
				addDetail: {}
			}
		},
		components: {
			bdmap
		},
		watch: {
			// 
			option: {
				handler(newVal, oldVal) {
					if (this.myChart) {
						if (newVal) {
							this.loading = false
							this.chartPie.setOption(newVal, true);
						} else {
							this.chartPie.setOption(oldVal);
						}
					} else {
						this.drawPieChart();
					}
				},
			}
		},
		created() {
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
			this.gethisInfo()

		},
		mounted() {
			this.panelInfo()
		},
		methods: {
			// 地图传过来的信息
			emitInfo(info) {
				let jsonInfo = JSON.parse(info)
				console.log(jsonInfo)
				this.mapBoor = false
				this.addDetail = jsonInfo
				this.formLabelAlign.place = jsonInfo.address
				this.formLabelAlign.longi = jsonInfo.longitude
				this.formLabelAlign.lati = jsonInfo.latitude
			},
			isshowfase() {
				this.mapBoor = false;
			},
			// 打开地图
			openBm() {
				this.mapBoor = true
			},
			// 面板统计
			panelInfo() {
				this.loading = true
				panelList({
						account: this.UserInfo[1]
					})
					.then(res => {
						this.loading = false
						this.totalNum = res.num
						this.config = {
							data: res.panel,
							colors: ["#4089ff", "#ff5599", "#fdd843", "#fd6a43", "#25d096"],
							unit: "单位",
							showValue: true,
						}
						this.echartsdata = res.panel
						this.option = {

							tooltip: {
								trigger: "item",
								formatter: "{a} <br/>{b} : {c} ({d}%)",
							},
							color: ["#4089ff", "#ff5599", "#fdd843", "#fd6a43", "#25d096"],
							series: [{
								name: '',
								type: 'pie',
								radius: ['50%', '60%'], //饼图大小
								labelLine: { //图形外文字线
									normal: {
										length: 5,
										length2: 10
									}
								},
								label: {
									normal: {
										formatter: '{c|{c}}  \n  {b|{b}}', //图形外文字上下显示
										borderWidth: 50,
										borderRadius: 4,
										// padding: [1, -60],          //文字和图的边距
										rich: {
											a: {
												color: '#333',
												fontSize: 16,
												lineHeight: 20
											},
											b: { //name 文字样式
												fontSize: 16,
												lineHeight: 30,
												color: '#CDCDD0',
											},
											c: { //value 文字样式
												fontSize: 14,
												lineHeight: 20,
												color: '#63BF6A',
												align: "center"
											}
										}
									}
								},
								data: res.panel
							}],
						}
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			// 返回用户管理
			addsut1() {
				this.$router.push({
					path: '/userAdmin'
				});
			},
			// 新增按钮
			addsut() {
				this.edittype = 0 //0表示新增项目，1表示修改项目
				this.centerDialogVisible = true;
				this.tit = "新增项目"
				this.formLabelAlign = {
					device_name: '',
					project_name: '',
					project_start_day: '',
					salesperson: '',
					engineer: '',
					phone: '',
					place: '',
					product_type: '',
					product_type_name: '',
					province: '',
					city: '',
					longi: '',
					lati: '',
					industry: '',
					url: '',
					sysuser:'',
					syspassword:'',
					sysip:'',
				}
				if (this.$refs['ruleForm']) {
					this.$refs['ruleForm'].clearValidate();
				}
			},
			// 修改项目
			editPro(data) {
				this.edittype = 1 //0表示新增项目，1表示修改项目
				this.centerDialogVisible = true;
				this.tit = "修改项目"
				this.formLabelAlign = {
					id: data.id,
					device_name: data.device_name,
					ht_name: data.ht_name,
					ht_no:data.ht_no,
					good: data.good,
					project_name: data.project_name,
					project_start_day: data.project_start_day,
					salesperson: data.salesperson,
					engineer: data.engineer,
					phone: data.phone,
					place: data.place,
					url: data.url,
					product_type: data.product_type,
					product_type_name: data.product_type_name,
					province: data.province,
					city: data.city,
					longi: data.longi,
					lati: data.lati,
					industry: data.industry,
					sysip:data.sysip,
					sysuser:data.sysuser,
					syspassword:data.syspassword,
				}
				
				console.log(this.formLabelAlign,"传过去的信息")
			},
			// 删除项目
			deletPro(data) {
				this.$confirm('此操作将删除该项目, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deletList({
							id: data.id,
						})
						.then(res => {
							this.$message.success(res.msg);
							this.gethisInfo()
							this.panelInfo()
						})
						.catch(err => {
							this.$message.error('删除失败');
						})
				})
			},
			// this.edittype = 0  //0表示新增项目，1表示修改项目
			// 新增
			onsubmit(formName) {
				console.log(this.formLabelAlign)
				this.$refs[formName].validate((valid) => {

					if (valid) {
						if (this.edittype == 0) {
							getAddlist({
									device_name: this.formLabelAlign.device_name,
									project_name: this.formLabelAlign.project_name,
									project_start_day: this.formLabelAlign.project_start_day,
									salesperson: this.formLabelAlign.salesperson,
									engineer: this.formLabelAlign.engineer,
									phone: this.formLabelAlign.phone,
									place: this.formLabelAlign.place,
									url: this.formLabelAlign.url,
									good: this.formLabelAlign.good,
									product_type: this.formLabelAlign.product_type,
									product_type_name: this.formLabelAlign.product_type_name,
									province: this.addDetail.province,
									city: this.addDetail.city,
									longi: this.addDetail.longitude,
									lati: this.addDetail.latitude,
									sysip: this.formLabelAlign.sysip,
									sysuser: this.formLabelAlign.sysuser,
									syspassword: this.formLabelAlign.syspassword,
									ht_name: this.formLabelAlign.ht_name,
									ht_no:this.formLabelAlign.ht_no,
			
								})
								.then(res => {
									this.$message.success('添加成功');
									console.log(this.formLabelAlign.device_name)
									this.gethisInfo()
									this.panelInfo()
									setTimeout(() => {
										this.centerDialogVisible = false
									}, 100);
								})
								.catch(err => {
									this.loading = false
									// this.$message.error('历史数据获取失败');
								})
						} else {
							editList({
									id: this.formLabelAlign.id,
									device_name: this.formLabelAlign.device_name,
									project_name: this.formLabelAlign.project_name,
									project_start_day: this.formLabelAlign.project_start_day,
									good: this.formLabelAlign.good,
									salesperson: this.formLabelAlign.salesperson,
									engineer: this.formLabelAlign.engineer,
									phone: this.formLabelAlign.phone,
									place: this.formLabelAlign.place,
									url: this.formLabelAlign.url,
									product_type: this.formLabelAlign.product_type,
									product_type_name: this.formLabelAlign.product_type_name,
									province: this.addDetail.province,
									city: this.addDetail.city,
									longi: this.addDetail.longitude,
									lati: this.addDetail.latitude,
									industry: this.formLabelAlign.industry,
									sysip: this.formLabelAlign.sysip,
									sysuser: this.formLabelAlign.sysuser,
									syspassword: this.formLabelAlign.syspassword,
									ht_name: this.formLabelAlign.ht_name,
									ht_no:this.formLabelAlign.ht_no,
									
								})
								.then(res => {
									this.$message.success('修改成功');
									this.gethisInfo()
									setTimeout(() => {
										this.centerDialogVisible = false
									}, 100);
								})
								.catch(err => {

									// this.$message.error('历史数据获取失败');
								})
						}
					}
				})
			},
			// 自己可查看项目
			onAuth() {
				authProjectlist({
						account: this.UserInfo[1]
					})
					.then(res => {
						this.loading = false
						this.tableData = res
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			handleSizeChange(val) {
				this.PageSize = val
				this.getResultsData()
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getResultsData()
			},
			//前端自己分页
			getResultsData() {
				// this指向改一下
				let that = this;
				let list = that.tableData; //后端回来表格的数据
				//渲染的数据newArry赋值
				this.newArry = list.filter(
					(item, index) =>
					index < that.currentPage * that.PageSize &&
					index >= that.PageSize * (that.currentPage - 1)
				); //根据页数显示相应的内容
				this.totalCount = list.length;
			},
			drawPieChart() {
				let mytextStyle = {
					color: "#fff",
					fontSize: 12,
					fontFamile: "PingFang-SC-Medium",
					fontStyle: "normal",
				};
				let mylabel = {
					show: true,
					position: "left",
					offset: [10, 20],
					formatter: "{b} : {c} ({d}%)",
					textStyle: mytextStyle,
				};
				this.chartPie = echarts.init(
					document.getElementById("chartPie"),
					"macarons"
				);
				this.chartPie.setOption(this.option);
			},
			// 搜索
			search() {
				this.currentPage = 1
				this.gethisInfo1()
			},
			gethisInfo1() {
				authProjectlist({
						auth: {
							"account": this.UserInfo[1]
						},
						search: {
							"project_name": this.form.name,
							"place": this.form.region,
							"product_type": this.form.region1
						}
					})
					.then(res => {
						// console.log(res)
						this.tableData = res
						this.getResultsData()
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			gethisInfo() {
				getProjectlist({
						auth: {
							"account": this.UserInfo[1]
						},
						search: {
							"project_name": this.form.name,
							"place": this.form.region,
							"product_type": this.form.region1
						}
					})
					.then(res => {
						// console.log(res)
						this.tableData = res
						this.getResultsData()
					})
					.catch(err => {
						this.loading = false
						// this.$message.error('历史数据获取失败');
					})
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				row.index = rowIndex;
			},
			// 打开不同的项目类型
			openDetails(row) {
				// sessionStorage.setItem("deviceInfo", JSON.stringify({type:'rfl'}))
				localStorage.setItem("deviceType", JSON.stringify({
					type: row.product_type,
					device: row.device_name
				}))
				let authinfo = JSON.parse(localStorage.getItem("authInfo"))
				localStorage.removeItem('spotArr')
				let autharr = []
				//  for (let i in authinfo) {
				//    if(authinfo[i][4] === row.device_name){
				//      autharr.push(authinfo[i][2])
				//      this.authsee = true
				//      break
				//    }else{
				//      this.authsee = false
				//    }
				//  }
				localStorage.setItem("autharr", JSON.stringify(autharr))
				switch (row.product_type) {
					case 'rfl':
						return this.$router.push({
							path: '/index'
						});
					case 'jrl':
						return this.$router.push({
							path: '/Jrindex'
						});
					case 'rql':
						return this.$router.push({
							path: '/Rqindex'
						});
				}
				// if(this.authsee){
				//              // 具有查看权限

				// }else{
				//   this.$message.error('暂无查看权限');
				// }
			},
			onSubmit() {
				this.gethisInfo()
			}
		}
	}
</script>
<style scoped lang="scss">
	.BMap_cpyCtrl {
		display: none;
	}

	.anchorBL {
		display: none;
	}

	.addform {
		padding: 0 2.5vw;
	}

	.main {
		width: 100vw;
		height: 92vh;
		background-color: #132735;

		// border:#168db2 1px solid;
		.mian-center {
			width: 90vw;
			height: 86vh;
			margin-left: 5vw;
			background-color: #071c2b;
			box-shadow: 0vh 1vh 2vh 0vh rgba(83, 93, 113, 0.06);
			border-radius: 1vh;
			opacity: 0.95;
		}

		.chaxu {
			margin-top: 1vh;
			height: 7vh;
			width: 90vw;
			//  border:#168db2 1px solid;
			line-height: 5vh;
		}

		.chaxu-row1 {
			width: 8vw;
			height: 2vh;
			line-height: 5vh;
			font-family: PingFang-SC-Regular;
			font-size: 3vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			color: #6acafb;
		}

		.top {
			height: 22vh;
			width: 90vw;
			//  border:#168db2 1px solid;
		}

		.top-row1 {
			height: 18vh;
			width: 24vw;
			margin-top: 2vh;
			background-color: #004b6e20;
			border-radius: 0vh;
			// opacity: 0.2;
			margin-left: 3vw;

			.lable1 {
				width: 8vw;
				height: 18vh;
				font-size: 8vh;
				line-height: 18vh;
				letter-spacing: 0vh;
				color: #ffffff;
				// margin-top:5vh;
				margin-left: 2vw;
			}

			.lable2 {
				width: 13vw;
				height: 18vh;
				font-family: PingFang-SC-Regular;
				font-size: 1.5vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 18vh;
				letter-spacing: 0vh;
				color: #6acafb;
			}
		}

		.fl {
			float: left;
		}

		.top-row2 {
			height: 22vh;
			width: 25vw;
			// border: 1px salmon solid;
			margin-left: 2vw;
			margin-top: 0.5vh;
		}

		.top-row3 {
			height: 22vh;
			width: 30vw;
			//  border:1px salmon solid;
			margin-left: 2vw;
		}



	}

	::v-deep {
		.el-input.is-disabled .el-input__icon {
			cursor: pointer;
		}

		.el-table::before {
			background-color: #071c2b !important;
		}

		.el-pager li.active {
			color: #409EFF;
			cursor: default;
		}

		.el-button--small {
			margin-top: 10px;
			margin-bottom: 10px;
		}

		.addPro {
			background-image: url("~@/assets/images/rfl_switch.png");
			background-size: 100% 100%;
			height: 82vh;

			.el-dialog__header {
				display: none;
			}

			.el-dialog__body {
				padding: 0px;
			}
			.el-form-item{
				height: 5vh;
			}
			.addBox {
				.evaluate-header {
					height: 4vh;

					// background: #5DCEF3;
					line-height: 6vh;
					//  background: #58cdf4;
					//  line-height: 50px;
					text-align: center;
					color: #0ef5fd;
					font-size: 3vh;
					position: relative;
					border-radius: 5px 5px 0 0;

					.evaluate-header-icon {
						width: 2vw;
						height: 2vh;
						position: absolute;
						right: 1vw;
						margin-top: -6vh;
						cursor: pointer;
					}

				}

				.flex {
					margin-top: 5vh;
				}

				.el-input {
					margin-bottom: 3vh;
				}

				.btn {
					width: 8vw;
					height: 5vh;
					background-color: #168db2;
					border-radius: 0.1vh;
					border: none;
					color: #fff;
					margin-top: 2vh;
					margin-left: 40vw;
					text-align: center;
					line-height: 5vh;
					cursor: pointer;
				}
			}
		}

		.el-icon-arrow-up {
			line-height: 20px !important;
		}

		.el-form-item {
			display: inline-block !important;
		}

		.el-form-item__label {
			width: 150px;
			color: #6acafb;
		}

		.el-input {
			width: 13vw;
			height: 30px;
		}

		.el-input__inner {
			background-color: #0c202f;
			height: 30px;
			border: 0.1px solid #6acafb;
			color: #fff;
		}

		.main-table {
			height: 62vh;
			width: 88vw;
			margin-top: 2vh;
			font-family: PingFang-SC-Regular;
		}

		.jiange {
			margin-top: 1vh;
			background-color: #0c202f;
		}

		.table-h {
			height: 45vh;
			margin-left: 1vw;
			background-color: #0c202f;
			font-family: PingFang-SC-Regular;

			th {
				padding: 0 !important;
				height: 4vh;
				line-height: 4vh;
				background-color: #183f54;
				color: #6acafb;
				font-size: 2vh;
				font-family: PingFang-SC-Regular;
			}

			td {
				padding: 0 !important;
				height: 4vh;
				line-height: 4vh;
				background-color: #0c202f;
				color: #fff;
			}
		}

		.el-table td,
		.el-table th.is-leaf,
		.el-table--border,
		.el-table--group {
			border-color: #183f56;
			border-right: 1px solid #183f56;
		}

		.el-table__body {
			border-left: 1px solid #183f56;
		}

		.el-table--border::after,
		.el-table--group::after,
		.el-table::before {
			background-color: #183f56;
		}

		.el-table tbody tr:hover>td {
			background-color: #168db2;
		}

		.el-input.is-disabled .el-input__inner {
			background-color: #0c202f;
			height: 30px;
			border: 0.1px solid #6acafb;
			color: #fff;
			cursor: pointer;
		}

		.subtn {
			width: 8vw;
			// height: 5vh;
			// line-height:5vh;
			background-color: #168db2;
			border-radius: 0.1vh;
			border: none;
			margin-left: 1vw;
		}
	}
</style>
