<template>
    <div class="bdmap">
              <div class="param-header">
      <span class="param-header-title">地点选择</span>
      <div class="param-header-icon" @click="closeCompon">
       <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="inpt">
          <el-form
            label-width="100px" 
            :model="businessDetail" 
            class="addform flex align-cen"
            ref="ruleForm"
            >
              <el-form-item label="地址：" prop="address" class=" ma-auto">
              <el-input v-model="businessDetail.address" clearable class="bbb"
              disabled
              ></el-input>
            </el-form-item>
              <el-form-item label="经度：" prop="longitude"
             class="item2  ma-auto"
              >
              <el-input v-model="businessDetail.longitude" clearable class="bbb"
               disabled
              ></el-input>
            </el-form-item>
              <el-form-item label="纬度：" prop="latitude"
              class="item2  ma-auto"
              >
              <el-input v-model="businessDetail.latitude" clearable class="bbb"
              disabled
              ></el-input>
            </el-form-item>
              <el-form-item label="搜索：" prop="keyword"
               class="item2 ma-auto"
              >
              <el-input v-model="keyword" clearable class="bbb"
              placeholder="请输入关键字搜索"
              @input="changeLocation"
              ></el-input>
            </el-form-item>
            <el-button type="primary"  class="subtn  ma-auto" @click="addConfirm"
            style="margin-right:7px"
                >确定</el-button>
          </el-form>
    </div>
    <div id="mapDiv" class="map-wrap"></div>
            <!-- <baidu-map
                class="map-wrap"
                :center="mapData.center"
                :zoom="mapData.zoom"
                @ready="mapHandler"
                @click="getLocation"
                
              >
               <bm-view style="width: 100%; height:63vh; flex: 1">
               
               </bm-view>
               
                  <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
                <bm-city-list anchor="BMAP_ANCHOR_TOP_RIGHT"
                @changeAfter="updateRegion"
                ></bm-city-list>
                <bm-geolocation
                  anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                  :showAddressBar="true"
                  :autoLocation="true"
                ></bm-geolocation>
              <bm-local-search :keyword="keyword" :auto-viewport="true" class="abs-city"></bm-local-search>
              </baidu-map> -->
    </div>
</template>
<script>
import debounce from '../utils/debounce.js' //防抖
var map;
var zoom = 14;
var geocoder;
export default {
    name:'bdmap',
     props:{
        sendformLabelAlign:{
            type:Object,
            default: {} // 默认值
        }
    },
    data() {
    return {
        input:'',
        projectData:{
        },
        keyword:'',
         loading:true,
      //地址信息(包含坐标信息)
      businessDetail:{
            address:'',
            province:'',
            city:'',
            longitude:'',
            latitude:'',
            keyword:''
      },
      gc: '',
      //地图数据
      mapData: {
        //中心坐标
        center: { lng: 0, lat: 0 },
        //缩放级别
        zoom:15
      },
      //BMap类
      BMap: null,
    }
  },
  created(){
      this.projectData = JSON.parse(localStorage.getItem("deviceType"))
      if(this.sendformLabelAlign.longi){
        this.businessDetail.address = this.sendformLabelAlign.place
        this.businessDetail.longitude = this.sendformLabelAlign.longi
        this.businessDetail.latitude = this.sendformLabelAlign.lati
      }
  },
  mounted() {
    let that = this;
     setTimeout(() => {
      this.loadMap();
    }, 100);
    },
  methods:{
    //   关闭地图
    closeCompon(){
         this.$emit('sendStatus', 'bdmap',false)
    },
      loadMap() {
          let that = this;
            //初始化地图对象
          map  = new T.Map('mapDiv');
          //设置显示地图的中心点和级别
            let lng = '116.25435';
            let lat = '40.08225';     
          if (this.businessDetail.longitude) {
            // 坐标存在
            lng = this.businessDetail.longitude
            lat = this.businessDetail.latitude
          }else{
           this.businessDetail.address ='北京市海淀区北清路81号中关村壹号B1座801'
            this.businessDetail.province = '北京'
            this.businessDetail.city = '北京'
             this.businessDetail.longitude = lng
            this.businessDetail.latitude = lat
             }
        map.centerAndZoom(new T.LngLat(lng, lat), zoom);    
            //创建缩放平移控件对象
            let control = new T.Control.Zoom();
            map.addControl(control);
            let menu = new T.ContextMenu({width: 140});
            let txtMenuItem = [
                {
                    text: '放大',
                    callback: function () {
                        map.zoomIn()
                    }
                },
                {
                    text: '缩小',
                    callback: function () {
                       map.zoomOut()
                    }
                },
                {
                    text: '放置到最大级',
                    callback: function () {
                       map.setZoom(18)
                    }
                },
                {
                    text: '查看全国',
                    callback: function () {
                       map.setZoom(4)
                    }
                },
                {
                    text: '获得右键点击处坐标',
                    isDisable: false,
                    callback: function (lnglat) {
                        alert(lnglat.getLng() + "," + lnglat.getLat());
                    }
                }
            ];
             for (var i = 0; i < txtMenuItem.length; i++) {

                //添加菜单项
                var item = new T.MenuItem(txtMenuItem[i].text, txtMenuItem[i].callback);
                //item.disable();
                menu.addItem(item);
                if (i == 1 || i == 3) {
                    //添加分割线
                    menu.addSeparator();
                }
            }
            map.addContextMenu(menu);
            var icon = new T.Icon({
            iconUrl: "http://api.tianditu.gov.cn/img/map/markerA.png",
            iconSize: new T.Point(19, 27),
            iconAnchor: new T.Point(10, 25)
        });
            let marker = new T.Marker(new T.LngLat(lng, lat), {icon: icon});
             map.addOverLay(marker);
             var cp = new T.CoordinatePickup(map, {callback: that.getLngLat})
                cp.addEvent();
    },
     getLngLat(lnglat) {
        let T = window.T;
        const x = lnglat.lng.toFixed(6);
        const y = lnglat.lat.toFixed(6);
        map.clearOverLays(); // 清空原来的标注点
        let point = new T.LngLat(x, y);
        let marker = new T.Marker(point);
        map.addOverLay(marker); // 添加标注点
        this.getGeocoderFun(x, y);
        },
       // 根据经纬度获取地址
         async getGeocoderFun(lon, lat) {
        let T = window.T;
        let geocoder = new T.Geocoder();
        geocoder.getLocation(new T.LngLat(lon, lat), (result) => {
        this.businessDetail.address = result.getAddress()
        this.businessDetail.longitude = lon
        this.businessDetail.latitude = lat
        });
      },
      // 输入定位
      changeLocation(){
         debounce(() => {
        let T = window.T;
        let geocoder = new T.Geocoder();
        geocoder.getPoint(this.keyword, this.searchResult);
         },200)
      },
      searchResult(result){
        console.log(result.getAddress())
            if(result.getStatus() == 0){
              map.panTo(result.getLocationPoint(), 14);
              this.getGeocoderFun(result.location.lon,result.location.lat)
              //创建标注对象
                  var marker = new T.Marker(result.getLocationPoint());
                  //向地图上添加标注
                  map.addOverLay(marker);
            }else{
            }
            
          },
      //地图预处理
    // async mapHandler({ BMap, map }) {
    //   if (this.businessId) {
    //     //可以在此处请求接口获取坐标数据
    //     await this.getMallBusinessDetail()
    //   }
    //   //保存百度地图类
    //   this.BMap = BMap
    //   //保存地图对象
    //   map = map
    //   //如果一开始坐标存在(编辑的时候)
    //   if (this.businessDetail.longitude && this.businessDetail.latitude) {
    //     //设置坐标
    //     mapData.center.lng = this.businessDetail.longitude
    //     mapData.center.lat = this.businessDetail.latitude
    //   } else {
    //     //如果坐标不存在则动态获取当前浏览器坐标（创建的时候）
    //     let geolocation = new BMap.Geolocation()
    //     //获取当前的坐标（使用promise 将异步转换为同步）
    //     await new Promise((resolve) => {
    //       geolocation.getCurrentPosition((r) => {
    //         this.businessDetail.address = r.address.province + r.address.city
    //         this.businessDetail.province = r.address.province
    //         this.businessDetail.city = r.address.city
    //         mapData.center.lng = this.businessDetail.longitude =
    //           r.point.lng
    //         mapData.center.lat = this.businessDetail.latitude = r.point.lat
    //         resolve()
    //       })
    //     })
    //   }
    //   //创建定位标记
    //   let marker = new BMap.Marker(
    //     new BMap.Point(
    //       this.businessDetail.longitude,
    //       this.businessDetail.latitude
    //     )
    //   )
    //   //将标记添加到地图上
    //   map.addOverlay(marker)
    // },
    // // 切换城市
    // updateRegion(){
    //  // 获取地图的中心点
    //             let point = map.getCenter()
    //             let that = this
    //             let BMapGL = this.BMap
    //              let geoc = new BMapGL.Geocoder()
    //             // 解析坐标点--获取坐标点所在的区域名称（城市）
    //              geoc.getLocation(point, (rs) => {

    //           //获取地址对象
    //           let addressComp = rs.addressComponents
    //           //拼接出详细地址
    //           this.businessDetail.address =
    //             addressComp.province +
    //             addressComp.city +
    //             addressComp.district +
    //             addressComp.street +
    //             addressComp.streetNumber
    //           this.businessDetail.province = addressComp.province
    //           this.businessDetail.city = addressComp.city
    //            this.businessDetail.longitude = rs.point.lng
    //            this.businessDetail.latitude = rs.point.lat
    //         })
    // },
    // //在地图上选择区域
    // getLocation(e) {
    //   //设置经度
    //   this.businessDetail.longitude = e.point.lng
    //   //设置纬度
    //   this.businessDetail.latitude = e.point.lat
    //   //百度地图类
    //   let BMapGL = this.BMap
    //   //地图对象
    //   let map = map
    //   //清除地图上所有的覆盖物(保证每次点击只有一个标记)
    //   map.clearOverlays()
    //   //创建定位标记
    //   let marker = new BMapGL.Marker(new BMapGL.Point(e.point.lng, e.point.lat))
    //   //将标记添加到地图上
    //   map.addOverlay(marker)
    //   //创建坐标解析对象
    //   let geoc = new BMapGL.Geocoder()
    //   //解析当前的坐标成地址
    //   geoc.getLocation(e.point, (rs) => {
    //     //获取地址对象
    //     let addressComp = rs.addressComponents
    //     //拼接出详细地址
    //     this.businessDetail.address =
    //       addressComp.province +
    //       addressComp.city +
    //       addressComp.district +
    //       addressComp.street +
    //       addressComp.streetNumber
    //     this.businessDetail.province = addressComp.province
    //     this.businessDetail.city = addressComp.city
    //   })
      
    // },
    // 点击确定
    addConfirm(){
        this.$emit('addInfo', JSON.stringify(this.businessDetail))
       
    },
  },
}
</script>
<style lang="scss" scoped>
.bdmap{
    width: 80vw;
    height: 80vh;
    position: fixed;
    left: 10vw;
    top: 10vh;
    z-index: 9999;
    background-image: url('~@/assets/images/anquanshezhiditu.png');
    background-size:100% 100%;
       .param-header {
    height: 6vh;
    //  background: #5DCEF3;
    line-height: 7vh;
    text-align: center;
    color: #041a28;
    font-size: 1.2vw;
    position: relative;
    border-radius: 5px 5px 0 0;
    .param-header-title {
      width: 179px;
      height: 28px;
      font-family: MicrosoftYaHei;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #0ef7ff;
     
    }
    .param-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      top: 0;
      cursor: pointer;
    }
       }
       .inpt{
           height: 10vh;
          margin-left: -64px;
       }
    .map-wrap{
        width: 99%;
        height: 63vh;
        margin: 0 auto;
        border-radius: 5px;
        .abs-city{
          display: none;
        }
    }
    .addform{
        height: 10vh;
    }
}
 ::v-deep{
     .el-input.is-disabled .el-input__inner{
         background-color: #0c202f;
    height: 30px;
    border: 0.1px solid #6acafb;
    color: #fff;
        cursor: not-allowed !important;
     }
     .el-form-item {margin-bottom: 0;}
 }
</style>